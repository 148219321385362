<template>
  <div>
    <div class="text-center mb-4">
      <span class="text-h6 text--secondary">
        <img
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          alt="Vuetify Logo"
          width="24"
          style="vertical-align: middle;"
          class="mr-1"
        />
      </span>
    </div>
    <div class="transition-wrapper">
      <div>
        <div class="text-center">
          <h1 class="headline mb-2">
            {{ $vuetify.lang.t("$vuetify.auth.sign-in.title") }}
          </h1>
          <span class="d-inline-block mb-8">{{
            $vuetify.lang.t("$vuetify.auth.sign-in.subtitle")
          }}</span>
        </div>

        <v-form>
          <v-text-field
            ref="input"
            v-model="email"
            class="mb-2"
            label="Email"
            name="login"
            type="text"
            hide-details="auto"
            outlined
            :error="$v.email.$invalid && email !== ''"
          />

          <v-text-field
            class="mb-10"
            v-model="password"
            :append-icon="show ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
            :label="
              $vuetify.lang.t('$vuetify.auth.sign-in-password.enter-password')
            "
            name="password"
            :type="show ? 'input' : 'password'"
            hide-details="auto"
            outlined
            :error="$v.password.$invalid && password !== ''"
            @click:append="show = !show"
          />
          <router-link
            to="/reset-password"
            class="d-inline-block text-body-2 text-decoration-none font-weight-bold mb-8"
            >{{
              $vuetify.lang.t("$vuetify.auth.sign-in.forgot-email")
            }}</router-link
          >
        </v-form>

        <div class="d-flex justify-space-between">
          <div />
          <v-btn
            class="text-none"
            style="min-width: 88px;"
            color="primary"
            depressed
            :disabled="$v.$invalid"
            @click="submitLoginForm"
          >
            LOGIN
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  components: {},

  validations: {
    email: {
      required,
      email
    },
    password: { required, minLength: minLength(6) }
  },

  data() {
    return {
      show: false,
      email: "",
      password: ""
    };
  },

  methods: {
    ...mapActions({
      login: "auth/login"
    }),

    async submitLoginForm() {
      try {
        const data = await this.login({
          email: this.email,
          password: this.password
        });

        this.$toast.success(`Welcome back, ${data.user.firstName}`);

        const redirectRoute =
          this.$route.query.redirectFrom &&
          this.$route.query.redirectFrom !== "/logout"
            ? this.$route.query.redirectFrom
            : { name: "home" };

        this.$router.push(redirectRoute);
      } catch (error) {
        this.$toast.error(error);
      }
    }
  }
};
</script>
